/* Styles for OTP Input Fields */
.otpInput {
    height: 66px;
    width: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    border: 2px solid #ccc;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  .otpInput:focus {
    border-color: #2e72bbf0;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
  }
  
  /* Wrapper for OTP Inputs */
  .otp-main {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: fit-content;
    margin: 0 auto;
    margin-top: 25px;
  }
  
  /* Continue Button */
  .otpContinuebtn {
    margin-top: 1.5rem;
    background-color: #2e72bbf0 !important;
    color: white !important;
    font-size: 18px !important;
    
    border-radius: 8px !important;
    padding: 10px 0 !important;
  }
  
  .otpContinuebtn:hover {
    background-color: #0056b3 !important;
  }
  

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px 8px 0 0; /* Rounded top corners */
    font-family: Arial, sans-serif;
    padding: 0 0 16px 0;
  
  }
  
  /* Left Section */
  .left {
    display: flex;
    flex-direction: column;
  }
  
  /* Header Title */
  .header-title {
    font-size: 22px;
    font-weight: 500;
    color: #333;
    margin: 0;
    line-height: 1.5;
  }
  
  /* Right Section */
  .right {
    display: flex;
    align-items: center;
  }
  
  /* Close Button */
  .right .dx-button {
    background-color: transparent !important;
    border: none !important;
    color: #333 !important;
    font-size: 18px !important;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .right .dx-button:hover {
    color: #d32f2f !important;
  }

  .bottomBorder{
    border-bottom: 1px solid;

  }

  .resend-otp{
    padding-top: 16px;
  }
  
  .resend-link{
    background-color: transparent;
    border: none;
    color: #2e72bbf0 ;
    font-size: 14px;
    font-weight: bold !important;
  }

  .resend-text{
    color: rgb(106, 101, 101);
    font-weight: 500;
  }